module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Monash Biotech","short_name":"Monash Biotech","start_url":"/en/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","crossOrigin":"use-credentials","icon":"src/favicons/apple-touch-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"f39849c854afb355084fc8b7d68efe58"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/en/","/contact-us/"],"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate","options":{"cacheName":"google-fonts-stylesheets"},"plugins":[{"handler":"CacheableResponsePlugin","options":{"statuses":[0,200]}}]},{"urlPattern":{},"handler":"StaleWhileRevalidate","options":{"cacheName":"static-resources","expiration":{"maxAgeSeconds":2592000}}},{"urlPattern":{},"handler":"NetworkOnly"},{"urlPattern":{},"handler":"StaleWhileRevalidate","options":{"cacheName":"third-party","expiration":{"maxAgeSeconds":86400}}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.monashbiotech.in/","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"debug":"true","localeJsonSourceName":"locale","languages":["en","es","cn","hi","fr","de","ar"],"defaultLanguage":"en","siteUrl":"https://www.monashbiotech.com","trailingSlash":"always","i18nextOptions":{"fallbackLng":"en","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LCXM72E22N","GTM-PDDBJZKS"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
